.carouselAfmf {
  .bgFixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 172, 219, 0.95);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .containerCarousel {
      width: 100vw;

      .imgCarousel {
        max-width: 90vw;
        max-height: 80vh;
        object-fit: contain;
      }

      .legend {
        width: 50%;
        margin-left: -25%;
        bottom: 0;
      }

      .carousel-status {
        left: 10px;
      }
    }

    .btnNext {
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      right: 30px;
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--policeWhite);
      font-size: 50px;
    }

    .btnPrev {
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      left: 30px;
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--policeWhite);
      font-size: 50px;
    }
  }
}
