.orgaAdmin {
  .containerCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;

    .containerCat {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .containerSubtitle {
        display: flex;
        align-items: center;

        .subTitle {
          margin: 20px 0;
        }

        .addNewMember {
          border: solid 1px var(--principale);
          border-radius: 50%;
          height: 20px;
          width: 20px;
          background: none;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .containerConseil {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }
}
