@media screen and (max-width: 810px) {
  .accueilSection {
    .header {
      height: 475px;

      .imgHeader {
        height: 400px;
      }

      .bg {
        height: 400px;
      }

      .title {
        height: 75px;
      }

      .containerTitleArticle {
        position: absolute;
        left: 15px;
        bottom: 90px;

        .subtitle {
          font-size: 16px;
        }
      }
    }

    .titleActu {
      margin: 50px 0 30px 0;
    }

    .containerArticles {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: unset;

      .oneArticle {
        margin-bottom: 30px;
        .articleTitle {
          font-size: 14px;
        }
      }
    }

    .linkActus {
      margin-left: unset;
      display: flex;
      justify-content: center;
    }

    .majoTitle {
      height: 50px;
      margin-top: 60px;
    }

    .categories {
      .containerCategories {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .oneCategory {
          .imgCategory {
            width: 300px;
            height: 300px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
