.afmfSection {
  .title {
    margin: 195px 0 30px 0;
    text-align: center;
    text-align-last: center;
  }

  .containerGeneral {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerCto {
      width: 70%;
      margin: 10px 0 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .imgCto {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 20px;
      }

      .subtitle {
        margin: 40px 0;
      }

      .containerFile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        margin-top: 40px;
        gap: 10px;

        .oneFile {
          display: flex;
          align-items: center;
          height: 100%;
  
          .pdf {
            margin-right: 10px;
          }
  
          a {
            text-decoration: none;
            color: var(--policeWhite);
          }
        }
      }

      .containerImgHisto {
        display: flex;
        justify-content: space-around;
        margin-top: 40px;
        width: 100%;

        .imgHisto {
          width: 300px;
          height: 300px;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }
}
