.addMember {
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 172, 219, 0.7);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .containerAddMember {
      background-color: var(--policeWhite);
      padding: 20px;
      width: 70vw;
      height: 90vh;
      border: solid 1px var(--principale);
      border-radius: 5px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: scroll;

      .formAddMember {
        display: flex;
        flex-direction: column;

        margin-top: 40px;

        .label {
          margin-bottom: 40px;

          .input {
            width: 450px;
          }
        }
      }
    }
  }
}
