* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --principale: #0059b8;
  --secondaire: #80acdb;
  --policeWhite: white;
  --police: black;
}

body {
  font-size: 18px;
  font-family: "Comfortaa", cursive;
}

h1 {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

h2 {
  font-size: 30px;
  font-weight: bold;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

h4 {
  font-size: 20px;
}

input {
  font-size: inherit;
  font-family: inherit;
  text-align: center;
  text-align-last: center;
  border: solid 1px var(--principale);
  border-radius: 5px;
  height: 50px;
}

.placeholderDisplay {
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  top: -25px;
  text-align: center;
}

.placeholderHidden {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: 5px;
  text-align: center;
}

.btnGeneral {
  min-height: 50px;
  padding: 0 30px;
  font-size: inherit;
  font-family: inherit;
  background-color: var(--principale);
  color: var(--policeWhite);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.labelImgWrong {
  border-radius: 5px;
  border: solid 1px red;
  height: 50px;
  width: 400px;
  margin: 20px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .newImg {
    display: none;
  }
}

.labelImgTrue {
  border-radius: 5px;
  border: solid 1px var(--principale);
  height: 50px;
  width: 400px;
  margin: 20px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .newImg {
    display: none;
  }
}

.quillParser {
  text-align: justify;

  h1 {
    font-family: "Comfortaa", cursive;
    text-align: center;
    text-align-last: center;
    font-size: 22px;
  }

  h2 {
    font-family: "Comfortaa", cursive;
    text-align: center;
    text-align-last: center;
    font-size: 20px;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-container {
    font-size: inherit;
    font-family: inherit;
  }

  ul {
    padding-left: 75px;
  }
}

@media screen and (max-width: 810px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }
}
