.sponsorsAdmin {
  padding-top: 175px;

  .containerAdd {
    display: flex;
    justify-content: center;

    .labelAddSponsor {
      border: solid 1px var(--principale);
      border-radius: 4px 0 0 4px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .newImg {
      display: none;
    }

    .btnAddSponsor {
      border-radius: 0 4px 4px 0;
    }
  }

  .sponsorDisplay {
    display: flex;
    justify-content: center;

    .containerSponsors {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 50px 0 40px 0;
      width: 70%;

      .oneSponsor {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 15px 0 15px;

        .sponsorImg {
          max-width: 300px;
          max-height: 300px;
          margin-bottom: 20px;
        }

        .btnDeleteSponsor {
          background-color: transparent;
          border: none;
          margin-top: 15px;
        }
      }
    }
  }
}
