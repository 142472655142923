.oneActu {
  .containerGeneral {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titlePage {
      margin: 195px 0 40px 0;
      text-align: center;
      text-align-last: center;
    }

    .text {
      width: 70%;
      line-height: 1.5rem;
      margin-bottom: 90px;
    }

    .containerImg {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 70%;

      .oneImg {
        width: 600px;
        text-align: center;
        text-align-last: center;
        margin-bottom: 40px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        .imgActus {
          width: 600px;
          height: 400px;
          object-fit: cover;
          pointer-events: none;
        }

        .altImg {
          width: 600px;
          word-wrap: break-word;
          margin: 10px 0;
          font-size: 14px;
        }
      }
    }

    .linkBack {
      display: block;
      position: fixed;
      top: 200px;
      left: 30px;
      font-size: 14px;
      text-decoration: none;
      color: var(--police);
      border: solid 1px var(--principale);
      border-radius: 20px;
      padding: 5px 10px;
    }

    .linkBackMobil {
      display: none;
    }
  }
}

.oneActuDisable {
  height: 100vh;
}
