@media screen and (max-width: 767px) {
  .mobil {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logoMobil {
      max-width: 70%;
      margin-bottom: 30px;
    }

    .msgMobil {
      width: 80%;
      text-align: center;
    }
  }
}
