.cardPublic {
  border: solid 1px var(--principale);
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2rem;
  width: 450px;
  margin: 20px;
  text-align: center;

  .role {
    font-weight: 700;
    font-style: italic;
  }
}
