.containerLoader {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--policeWhite);
  opacity: 0.8;
  z-index: 2500;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  z-index: 2501;
}

.loader {
  color: var(--secondaire);
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: fixed;
  top: 40%;
  left: 47%;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before,
.loader:after {
  position: absolute;
  content: "";
}

.loader:before {
  width: 5em;
  height: 10em;
  background: var(--secondaire);
  border-radius: 10em 0 0 10em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5em 5em;
  transform-origin: 5em 5em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}

.loader:after {
  width: 5em;
  height: 10em;
  background: var(--secondaire);
  border-radius: 0 10em 10em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0em 5em;
  transform-origin: 0em 5em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
