@media screen and (max-width: 767px) {
  .forgetSection {
    .logo {
      width: 80vw;
      height: auto;
    }

    .inputForget {
      width: 90vw;
    }
  }
}
