.notFoundPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .containerNotFound {
    display: flex;
    flex-direction: column;
    align-items: center;

    .imgNotFound {
      width: 250px;
      margin-bottom: 50px;
    }

    .textNotFound {
      font-weight: bold;
    }
  }
}
