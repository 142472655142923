.displayNone {
  display: none;
}

.containerPopupConfirm {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .containerTextPopupConfirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondaire);
    border-radius: 20px;
    width: auto;
    height: auto;
    padding: 30px;
    color: var(--policeWhite);

    .containerBtns {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      .btnYesNoConfirm {
        background-color: var(--principale);
        border: none;
        border-radius: 20px;
        height: 40px;
        width: 75px;
        color: var(--policeWhite);
        font-size: 18px;
        margin-top: 30px;
        cursor: pointer;
      }

      .btnYesNoConfirm:nth-child(1) {
        margin-right: 10px;
      }

      .btnYesNoConfirm:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
