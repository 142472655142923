.accueilSection {
  .header {
    height: 760px;
    width: 100%;
    background-color: var(--secondaire);
    position: relative;

    .imgHeader {
      width: 100%;
      height: 700px;
      object-fit: cover;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 700px;
      background-color: rgba(0, 89, 184, 0.5);
    }

    .title {
      height: 60px;
      color: var(--policeWhite);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .containerTitleArticle {
      position: absolute;
      color: var(--policeWhite);
      bottom: 150px;
      z-index: 3;
      left: 100px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;

      .subtitle {
        font-size: 20px;
        color: var(--policeWhite);
      }
    }
  }

  .titleActu {
    margin: 135px 0 40px 0;
    text-align: center;
    text-align-last: center;
  }

  .containerArticles {
    display: flex;
    justify-content: space-between;
    margin: 0 20% 30px 20%;

    .oneArticle {
      width: 300px;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: var(--police);

      .imgArticle {
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
      }

      .articleTitle {
        width: 100%;
        text-align: center;
        text-align-last: center;
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }

  .linkActus {
    text-decoration: none;
    color: var(--police);
    margin-left: 20%;
  }

  .majoTitle {
    height: 150px;
    margin-top: 100px;
    background-color: var(--secondaire);
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      color: var(--policeWhite);
    }
  }

  .categories {
    display: flex;
    justify-content: center;

    .containerCategories {
      margin-top: 75px;
      display: grid;
      grid-template-columns: 400px 400px;
      grid-gap: 40px 100px;

      .oneCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: var(--police);

        .imgCategory {
          width: 400px;
          height: 400px;
          object-fit: cover;
          border-radius: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
