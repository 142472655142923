.navbarAdmin {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100px;
  background-color: var(--secondaire);
  width: 100%;

  .containerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100px;

    .logo {
      height: 85%;
    }
  }

  .containerList {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;

    .listLink {
      text-decoration: none;

      .listName {
        font-size: 22px;
        font-weight: bold;
        color: var(--policeWhite);
      }

      .listDisable {
        display: none;
      }
    }

    .btnDisconnect {
      background-color: inherit;
      border: none;
      font-size: 22px;
      font-weight: bold;
      font-family: inherit;
      color: var(--policeWhite);
      cursor: pointer;
    }
  }
}
