@media screen and (max-width: 810px) {
  .displayBg {
    background-color: var(--secondaire);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: block;
    animation: enterTop 0.5s linear;
  }

  .displayBgNone {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    display: block;
  }

  .displayNormal {
    background-color: var(--secondaire);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: block;
  }

  .navMobil {
    height: 75px;
    width: 100%;
    display: block;

    .containerLogo {
      display: flex;
      justify-content: center;
      height: 75px;

      .logo {
        height: 100%;
        padding: 5px;
      }
    }

    .containerSpan {
      position: absolute;
      top: 27.5px;
      right: 20px;
      width: 30px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: transparent;
      border: none;

      .bar {
        border: solid 1.5px var(--policeWhite);
      }
    }

    .menu {
      position: fixed;
      background-color: var(--principale);
      z-index: 20;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.5s linear;
    }

    .menuHidden {
      background-color: var(--principale);
      z-index: 2;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: -100vh;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.5s linear;
    }

    .closeBtn {
      position: absolute;
      top: 30px;
      right: 30px;
      border: none;
      background-color: transparent;
      font-size: 20px;
      font-family: inherit;
      font-weight: bold;
      color: var(--policeWhite);
    }

    .logoMenu {
      height: 75px;
    }

    .item {
      text-decoration: none;
      color: var(--policeWhite);
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    .containerHover {
      display: flex;
      flex-direction: column;

      .itemBtn {
        background-color: transparent;
        border: none;
        color: var(--policeWhite);
        font-size: 16px;
        font-family: inherit;
        font-weight: bold;
      }

      .openHover {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;

        .categoryTitle {
          text-decoration: none;
          color: var(--policeWhite);
          margin-top: 10px;
        }
      }
    }
  }
}

@keyframes enterTop {
  from {
    top: -120px;
  }
  to {
    top: 0px;
  }
}
