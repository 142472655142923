.forgetSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  padding: 15vh 0;

  .logo {
    height: 300px;
    margin-bottom: 30px;
  }

  .inputForget {
    width: 400px;
  }

  .btnGeneral {
    margin-top: 30px;
  }
}
