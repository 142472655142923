.containerCal {
  position: relative;
  display: flex;
  justify-content: center;

  .btnDate {
    background-color: transparent;
    border: solid 1px var(--principale);
    border-radius: 4px;
    font-size: inherit;
    font-family: inherit;
    width: 275px;
    height: 50px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .cal {
    position: absolute;
    top: 50px;
    z-index: 2;
  }
}
