@media screen and (max-width: 810px) {
  .sponsorsPublic {
    .titleSponsors {
      margin: 110px 0 30px 0;
    }

    .allSponsors {
      .containerImg {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;

        .oneSponsor {
          margin: 40px 0;
        }
      }
    }
  }
}
