@media screen and (max-width: 810px) {
  .oneActu {
    .containerGeneral {
      .titlePage {
        margin: 110px 0 30px 0;
      }

      .text {
        width: 85%;
        margin-bottom: 60px;

        h1 {
          font-size: 15px;
        }

        h2 {
          font-size: 14px;
        }
      }

      .containerImg {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .oneImg {
          width: 300px;
          margin-bottom: 30px;

          .imgActus {
            width: 300px;
            height: 199px;
            object-fit: cover;
          }

          .altImg {
            width: 300px;
            font-size: 12px;
          }
        }
      }

      .linkBack {
        display: none;
      }

      .linkBackMobil {
        display: block;
        position: fixed;
        top: 30px;
        left: 20px;
        z-index: 20;
        color: var(--policeWhite);
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}
