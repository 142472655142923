@media screen and (max-width: 810px) {
  .displayBg {
    display: none;
  }

  .displayBgNone {
    display: none;
  }

  .navbarPublic {
    display: none;
  }
}
