.containerQuill {
  width: 100%;
  display: flex;
  justify-content: center;

  .quillDisplay {
    width: 70%;
    height: 350px;
    margin: 0 20px 80px 20px;
    text-align: center;

    p {
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      color: var(--police);
    }

    h1 {
      font-family: "Comfortaa", cursive;
      text-align: center;
      text-align-last: center;
      font-size: 22px;
    }

    h2 {
      font-family: "Comfortaa", cursive;
      text-align: center;
      text-align-last: center;
      font-size: 20px;
    }

    .ql-container {
      font-size: inherit;
      font-family: inherit;
    }
  }
}
