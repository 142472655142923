@media screen and (max-width: 767px) {
  .newPassword {
    .logo {
      width: 80vw;
      height: auto;
    }

    .containerForm {
      .labelNewPword {
        .inputLogin {
          width: 90vw;
        }

        .placeholderDisplay {
          width: 90vw;
        }

        .placeholderHidden {
          width: 90vw;
        }
      }
    }
  }
}
