.profilAdmin {
  padding-top: 175px;

  hr {
    margin: 50px 0 30px 15%;
    width: 70%;
    color: var(--principale);
  }

  .subNavbar {
    position: fixed;
    left: 10px;
    display: flex;
    flex-direction: column;

    a {
      margin: 10px 0;
      text-decoration: none;
      cursor: pointer;
      color: inherit;
    }
  }

  .disallowAcces {
    display: none;
  }

  .title {
    margin-bottom: 40px;
  }

  .listUsers {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tableUser,
    td,
    th {
      border-collapse: collapse;
      border: solid 1px var(--principale);
      text-align: center;
      padding: 10px 15px;
    }

    .tableUser {
      margin-bottom: 40px;
    }

    .btnModify {
      background-color: var(--principale);
      color: var(--policeWhite);
      font-size: inherit;
      border: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      cursor: pointer;
    }

    .btnDisabled {
      display: none;
    }

    .addUserGlobal {
      .bg {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(128, 172, 219, 0.7);
        width: 100vw;
        height: 100vh;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;

        .containerAddUser {
          background-color: var(--policeWhite);
          padding: 20px;
          border: solid 1px var(--principale);
          border-radius: 5px;
          position: relative;

          .title {
            text-align: center;
            text-align-last: center;
            margin: 50px 0 40px 0;
          }

          .containerFormAdd {
            display: flex;
            flex-direction: column;

            .labelUser {
              position: relative;
              margin-bottom: 30px;

              p {
                width: 400px;
              }

              .inputUser {
                width: 400px;
                position: relative;
              }

              .selectRole {
                width: 400px;
                height: 50px;
                text-align: center;
                font-size: inherit;
                font-family: inherit;
                background-color: transparent;
                border: solid 1px var(--principale);
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }

          .containerBtnAdd {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .containerForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .formModifyProfil {
      display: flex;
      flex-direction: column;

      .labelForm {
        position: relative;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          width: 400px;
        }

        .inputProfil {
          width: 400px;
          position: relative;
        }

        .pwordDisplay {
          background-color: inherit;
          border: none;
          font-family: inherit;
          margin-top: 5px;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
  }

  .mailSubjectAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerMapSubject {
      margin-bottom: 30px;

      .mapSubject {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .deleteBtn {
          background-color: var(--principale);
          border: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          color: var(--policeWhite);
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .containerNewSubject {
      display: flex;
      flex-direction: column;
      align-items: center;

      .labelNewSubject {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        p {
          width: 400px;
        }

        .inputNewObject {
          width: 400px;
          position: relative;
        }
      }

      .btnAddSubject {
        margin-top: 30px;
      }
    }
  }

  .legalNoticeAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;

    .quillDisplay {
      width: 60%;
      height: 350px;

      .ql-container {
        font-family: inherit;
        font-size: inherit;
      }
    }

    .btnModifyLegalNotice {
      margin: 70px 0 50px 0;
    }
  }
}
