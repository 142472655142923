.majoretteSection {
  .containerGeneral {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 195px 0 40px 0;
      text-align: center;
      text-align-last: center;
    }

    .grid {
      width: 80%;
      display: flex;
      justify-content: center;

      .containerText {
        width: 80%;
        padding: 0 60px 0 0;
      }

      .coming {
        width: 300px;
        border: solid 1px var(--principale);
        border-radius: 20px;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .subtitle {
          text-align: center;
          text-align-last: center;
          margin-bottom: 40px;
        }

        .containerTextComing {
          text-align: center;
          text-align-last: center;
          line-height: 2rem;
        }

        .file {
          margin-top: 50px;
          text-align: center;
        }
      }
    }
  }

  .interTitle {
    text-align: center;
    text-align-last: center;
    margin-top: 90px;
  }

  .containerArticles {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .oneArticle {
      width: 800px;
      height: 200px;
      display: flex;
      align-items: center;
      border: solid 1px var(--principale);
      border-radius: 20px;
      margin-bottom: 40px;
      text-decoration: none;
      color: var(--police);

      .imgArticle {
        width: 320px;
        height: 200px;
        object-fit: cover;
        border-radius: 20px 0 0 20px;
        z-index: -1;
      }

      .articleTitle {
        margin-left: 30px;
      }
    }
  }
}
