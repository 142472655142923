@media screen and (min-width: 811px) {
  .displayBg {
    background-color: var(--secondaire);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    animation: enterTop 0.5s linear;
    z-index: 2;
  }

  .displayBgNone {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .displayNormal {
    background-color: var(--secondaire);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .navbarPublic {
    width: 100%;
    height: 120px;
    display: flex;

    .containerLogo {
      height: 120px;

      .logo {
        height: 100%;
        padding: 10px;
      }
    }

    .containerNav {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .linkTitle {
        text-decoration: none;
        color: var(--policeWhite);
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .link {
        text-decoration: none;
        color: var(--policeWhite);
        font-size: 24px;
      }

      .items {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .containerHover {
        position: relative;
        display: flex;
        justify-content: center;

        .openHover {
          position: absolute;
          top: 25px;
          width: 300px;
          border: solid 1px var(--principale);
          border-radius: 20px;
          background-color: var(--secondaire);
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .categoryTitle {
            color: var(--policeWhite);
            margin: 10px 0;
            cursor: pointer;
            text-decoration: none;
          }

          .categoryTitle:hover {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@keyframes enterTop {
  from {
    top: -120px;
  }
  to {
    top: 0px;
  }
}
