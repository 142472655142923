.newPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  padding: 15vh 0;

  .logo {
    height: 300px;
    margin-bottom: 30px;
  }

  .containerForm {
    display: flex;
    flex-direction: column;

    .labelNewPword {
      position: relative;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        width: 400px;
      }

      .inputLogin {
        width: 400px;
        position: relative;
      }

      .pwordDisplay {
        background-color: inherit;
        border: none;
        font-family: inherit;
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}
