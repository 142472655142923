.majoAdmin {
  padding-top: 175px;

  .containerSelect {
    display: flex;
    justify-content: center;

    .selectCategories {
      border: solid 1px var(--principale);
      border-radius: 4px;
      font-size: inherit;
      font-family: inherit;
      background-color: transparent;
      height: 50px;
      width: 350px;
      text-align: center;
    }
  }

  .containerCategories {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerImg {
      display: flex;
      flex-direction: column;
      align-items: center;

      .imgCover {
        width: 400px;
        height: 400px;
        object-fit: cover;
        margin-top: 40px;
        border-radius: 20px;
      }

      .labelCover {
        margin: 40px 0 0 0;

        .newImgCover {
          display: none;
        }
      }
    }

    .formMajo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;

      .inputTitle {
        margin: 40px 0;
      }

      .labelCheckbox {
        display: flex;
        align-items: center;

        .inputCheckbox {
          margin: 0 15px;
        }
      }

      .formComing {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .containerFile {
      margin-top: 40px;

      .deleteFile {
        background-color: transparent;
        border: solid 1px var(--principale);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .containerUploadFile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;

      .labelFile {
        width: 400px;
        height: 50px;
        border: solid 1px var(--principale);
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px;

        .inputFile {
          display: none;
        }
      }
    }
  }
}
