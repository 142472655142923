@media screen and (max-width: 810px) {
  .contactPublic {
    .title {
      margin: 110px 0 30px 0;
    }

    .formContact {
      .labelContact {
        margin-bottom: 20px;

        .inputContact {
          width: 85vw;
        }

        .inputMessage {
          width: 85vw;
          height: 200px;
        }
      }

      .selectContact {
        width: 85vw;
        margin-bottom: 20px;
      }

      .btnSubmitForm {
        margin-top: 20px;
      }
    }
  }
}
