.containerFilter {
  width: 300px;
  height: 550px;
  border: solid 1px var(--principale);
  border-radius: 20px;
  margin-left: 60px;
  display: flex;
  justify-content: center;

  .title {
    margin: 40px 0;
    text-align: center;
    text-align-last: center;
  }

  .labelFilter {
    display: flex;
    align-items: center;

    .inputFilter {
      margin-right: 10px;
    }

    .subtitle::first-letter {
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 810px) {
  .containerFilter {
    width: 200px;
    height: 500px;
    margin-left: unset;
    margin-bottom: 30px;
  }
}
