.souvenirSection {
  .titlePage {
    margin: 195px 0 40px 0;
    text-align: center;
    text-align-last: center;
  }

  .containerGeneral {
    display: flex;
    justify-content: center;

    .containerBtnFilter {
      display: none;
    }

    .containerArticles {
      display: flex;
      flex-direction: column;
      align-items: center;

      .oneArticle {
        width: 800px;
        height: 200px;
        border: solid 1px var(--principale);
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        text-decoration: none;
        color: var(--police);

        .imgArticle {
          width: 320px;
          height: 100%;
          object-fit: cover;
          border-radius: 20px 0 0 20px;
        }

        .titleArticle {
          margin-left: 30px;
        }
      }
    }

    .filterDisplay {
      display: block;
    }
  }
}
