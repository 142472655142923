.popupMessageGlobal {
  display: flex;
  justify-content: center;

  .openModalTrue {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 50px;
    opacity: 1;
    background-color: var(--secondaire);
    padding: 5px 25px;
    z-index: 9999;
    transition: all 0.5s ease;
  }

  .openModalFalse {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: -100px;
    opacity: 0;
    background-color: var(--secondaire);
    padding: 5px 25px;
    z-index: 9999;
    transition: all 0.5s ease;
  }

  .warningMessage {
    background-color: transparent;
    color: red;
    margin-right: 15px;
    font-size: 20px;
  }

  .goodMessage {
    background-color: transparent;
    color: green;
    margin-right: 15px;
    font-size: 20px;
  }

  .message {
    color: var(--policeWhite);
  }
}
