.sponsorsPublic {
  .titleSponsors {
    margin: 195px 0 40px 0;
    text-align: center;
    text-align-last: center;
  }

  .allSponsors {
    display: flex;
    justify-content: center;

    .containerImg {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 70%;

      .oneSponsor {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 100px;

        .imgSponsor {
          max-width: 200px;
          max-height: 200px;
        }

        .subtitle {
          margin: 10px 0;
          font-size: 14px;
        }
      }
    }
  }
}
