.card {
  border: solid 1px var(--principale);
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2rem;
  width: 450px;
  margin: 20px;

  .role {
    font-weight: 700;
    font-style: italic;
  }

  .containerBtn {
    .BtnCard {
      background-color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid var(--secondaire);
      margin: 0 5px;
      cursor: pointer;
      font-size: 18px;
    }
  }
}
