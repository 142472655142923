.legalNotice {
  .containerGeneral {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 195px 0 40px 0;
    }

    .text {
      width: 70%;
      line-height: 1.5rem;
    }
  }
}
