.contactPublic {
  .title {
    margin: 195px 0 40px 0;
    text-align: center;
    text-align-last: center;
  }

  .formContact {
    display: flex;
    flex-direction: column;
    align-items: center;

    .labelContact {
      margin-bottom: 40px;

      .inputContact {
        width: 500px;
      }

      .inputMessage {
        width: 60vw;
        height: 300px;
        border: solid 1px var(--principale);
        border-radius: 4px;
        font-family: inherit;
        font-size: inherit;
        padding: 10px;
      }
    }

    .selectContact {
      width: 500px;
      height: 50px;
      border: solid 1px var(--principale);
      border-radius: 4px;
      background-color: transparent;
      font-size: inherit;
      font-family: inherit;
      text-align: center;
      text-align-last: center;
      margin-bottom: 40px;
    }

    .btnSubmitForm {
      margin-top: 40px;
    }
  }
}
