.actusAdmin {
  padding-top: 175px;

  .containerTitle {
    text-align: center;
  }

  .openModal {
    display: flex;
    justify-content: center;
    margin: 40px 0;

    .openModalBtn {
      border: solid 1px var(--principale);
      background-color: transparent;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
    }
  }

  .filter {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .labelFilter {
      display: flex;
      align-items: center;
      margin: 0 20px;

      .inputFilter {
        margin-right: 5px;
      }
    }
  }

  .containerArticleFilter {
    display: flex;
    justify-content: center;

    .containerMapArticle {
      display: flex;
      flex-direction: column;
      align-items: center;

      .oneArticle {
        width: 800px;
        height: 200px;
        border: solid 1px var(--principale);
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        position: relative;

        .imgArticle {
          width: 320px;
          height: 100%;
          object-fit: cover;
          border-radius: 20px 0 0 20px;
        }

        .titleArticle {
          margin-left: 30px;
        }

        .btnModify {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 20px 0 20px;
          width: 175px;
        }

        .btnDelete {
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 20px 0 20px 0;
          width: 175px;
        }
      }
    }
  }

  .addArticleGlobal {
    .bg {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(128, 172, 219, 0.7);
      width: 100vw;
      height: 100vh;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      .containerAddArticle {
        background-color: var(--policeWhite);
        padding: 20px;
        width: 70vw;
        height: 90vh;
        border: solid 1px var(--principale);
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;

        .selectTheme {
          background-color: transparent;
          min-height: 50px;
          border: solid 1px var(--principale);
          border-radius: 5px;
          margin: 40px 0 20px 0;
          padding: 0 20px;
          font-size: inherit;
          font-family: inherit;
        }

        .formArticle {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .inputTitle {
            margin: 20px 0 40px 0;
            width: 275px;
          }

          .labelCheckbox {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .inputCheckbox {
              margin-left: 10px;
            }
          }

          .labelNewImg {
            border: solid 1px var(--principale);
            border-radius: 5px;
            height: 50px;
            width: auto;
            display: flex;
            align-items: center;
            padding: 0 20px;
            margin-bottom: 40px;
            cursor: pointer;

            .newImg {
              display: none;
            }
          }
        }

        .containerImg {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          width: 80%;
          margin: 20px 0;

          .cartImg {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 0;

            .imgArticle {
              width: 300px;
              height: 199px;
              object-fit: cover;
            }

            .imgDescription {
              font-size: 14px;
              margin-top: 10px;
              width: 300px;
              word-wrap: break-word;
              text-align: center;
              text-align-last: center;
            }

            .imgFirst {
              display: flex;
              align-items: center;

              input {
                margin-left: 5px;
              }
            }

            .btnTrash {
              border: none;
              background-color: transparent;
              font-size: 25px;
              cursor: pointer;
              padding-top: 15px;
            }
          }
        }

        .containerAddImgBtn {
          display: flex;
          margin-bottom: 40px;

          .labelNewImg {
            border: solid 1px var(--principale);
            border-radius: 5px 0 0 5px;
            height: 50px;
            width: auto;
            display: flex;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;

            .newImg {
              display: none;
            }
          }

          .btnAddImg {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }
}
