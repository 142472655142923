.afmfAdmin {
  padding-top: 175px;

  hr {
    margin: 50px 0 30px 0;
    width: 70%;
    color: var(--principale);
  }

  .containerTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .containerImgCto {
    display: flex;
    flex-direction: column;
    align-items: center;

    .imgCto {
      width: 200px;
      height: 200px;
      border-radius: 20px;
      object-fit: cover;
    }

    .labelCto {
      margin: 40px 0 0 0;
    }
  }

  .containerTexts {
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputTitle {
      width: 400px;
      margin-bottom: 40px;
    }

    .containerFiles {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 70%;
      margin-top: 40px;
      gap: 10px;

      .oneFile {
        display: flex;
        align-items: center;
        height: 100%;

        .pdf {
          margin-right: 10px;
        }

        a {
          text-decoration: none;
          color: var(--policeWhite);
        }
      }

      .deleteFile {
        background-color: transparent;
        border: solid 1px var(--policeWhite);
        color: var(--policeWhite);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .containerUploadFile {
      display: flex;
      flex-direction: column;
      align-items: center;

      .labelFile {
        width: 400px;
        height: 50px;
        border: solid 1px var(--principale);
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px;

        .inputFile {
          display: none;
        }
      }
    }
  }

  .containerImgHisto {
    margin-top: 40px;
    width: 70%;
    display: flex;
    justify-content: space-around;

    .imgHisto {
      width: 300px;
      height: 300px;

      .inputImg {
        display: none;
      }

      .img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
        cursor: pointer;
      }

      .labelImg {
        width: 300px;
        height: 300px;
        border: solid 1px var(--principale);
        border-radius: 20px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        color: var(--principale);
      }
    }
  }
}
