@media screen and (max-width: 810px) {
  .souvenirSection {
    .titlePage {
      margin: 110px 0 30px 0;
    }

    .containerGeneral {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .containerBtnFilter {
        display: flex;
        align-items: flex-start;
        order: 1;
        margin-bottom: 30px;

        .btnFilter {
          background-color: transparent;
          border: none;
          margin-left: 5px;
        }
      }

      .btnDisplayNone {
        display: none;
      }

      .containerArticles {
        width: 100%;

        .oneArticle {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          .imgArticle {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 20px 20px 0 0;
          }

          .titleArticle {
            margin: 20px 0;
          }
        }
      }

      .filterDisplay {
        display: none;
      }

      .filterOpen {
        display: block;
      }
    }
  }
}
