.organigrammePublic {
  width: 90vw;

  .containerCards {
    .categories {
      display: flex;
      flex-direction: column;
      align-items: center;

      .catTitle {
        margin: 20px 0 40px 0;
      }

      .members {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }
}
