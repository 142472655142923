@media screen and (max-width: 810px) {
  .afmfSection {
    .title {
      margin: 110px 0 30px 0;
    }

    .containerGeneral {
      .containerCto {
        width: 85%;
        margin: 0 0 30px 0;

        .subtitle {
          margin: 30px 0;
        }

        .text {
          h1 {
            font-size: 15px;
          }

          h2 {
            font-size: 14px;
          }
        }

        .containerFile {
          display: flex;
          flex-direction: column;
          align-items: center;

          .oneFile {
            padding-bottom: 20px;
          }

          .oneFile:last-child {
            padding-bottom: unset;
          }
        }

        .containerImgHisto {
          display: flex;
          flex-direction: column;
          align-items: center;

          .imgHisto {
            margin-bottom: 30px;
          }

          .imgHisto:last-child {
            margin-bottom: unset;
          }
        }
      }
    }
  }
}
