@media screen and (max-width: 810px) {
  .majoretteSection {
    .containerGeneral {
      display: flex;

      .title {
        margin: 110px 0 30px 0;
      }

      .grid {
        display: flex;
        flex-direction: column-reverse;
        width: 85%;

        .containerText {
          width: 100%;
          padding: unset;

          h1 {
            font-size: 15px;
          }

          h2 {
            font-size: 14px;
          }
        }

        .coming {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }

    .interTitle {
      margin-top: 60px;
    }

    .containerArticles {
      margin-top: 30px;

      .oneArticle {
        width: 85%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .imgArticle {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 20px 20px 0 0;
        }

        .articleTitle {
          margin: 20px 0;
        }
      }
    }
  }
}
