.mobil {
  display: none;
}

.loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  min-height: 100vh;

  .logoDesktop {
    height: 300px;
    margin: 60px 0;
  }

  .containerForm {
    display: flex;
    flex-direction: column;

    .labelForm {
      position: relative;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        width: 400px;
      }

      .inputLogin {
        width: 400px;
        position: relative;
      }

      .pwordDisplay {
        background-color: inherit;
        border: none;
        font-family: inherit;
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .forgetPassword {
    margin-top: 50px;
    text-decoration: none;
    color: inherit;
    font-size: 13px;
  }
}
