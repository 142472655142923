.containerBtnClose {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999;

  .btnClose {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: var(--secondaire);
    color: var(--policeWhite);
    cursor: pointer;
  }
}
