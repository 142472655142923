@media screen and (max-width: 810px) {
  .footer {
    .linkFooter {
      font-size: 14px;
    }

    .logoFb {
      width: 40px;
    }

    .containerMaker {
      .subtitle {
        font-size: 12px;
      }

      .logoDwM {
        width: 50px;
      }
    }
  }
}
