@media screen and (max-width: 810px) {
  .carouselAfmf {
    .bgFixed {
      .btnNext {
        right: 0;
        top: calc(50% - 30px);
      }

      .btnPrev {
        left: 0;
        top: calc(50% - 30px);
      }
    }
  }
}
