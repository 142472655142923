.adminAccueil {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .welcome {
    font-size: 40px;
    font-weight: bold;
  }
}
