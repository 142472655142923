.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--principale);
  margin-top: 90px;

  .linkFooter {
    text-decoration: none;
    color: var(--policeWhite);
    margin-top: 20px;
    font-size: 20px;
  }

  .logoFb {
    width: 50px;
  }

  .containerMaker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 30px 0;

    .subtitle {
      color: var(--policeWhite);
      font-size: 16px;
    }

    .logoDwM {
      width: 80px;
      margin-top: 10px;
    }
  }
}
