@media screen and (max-width: 810px) {
  .legalNotice {
    .containerGeneral {
      .title {
        margin: 110px 0 30px 0;
      }

      .text {
        width: 85%;

        h1 {
          font-size: 15px;
        }

        h2 {
          font-size: 14px;
        }
      }
    }
  }
}
